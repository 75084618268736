<template>
  <div class="login">
    <div class="login-form">
      <div class="login-form-item">
        <div class="input-label">Phone Number</div>
        <el-input v-model="loginInfo.phone" placeholder="Phone Number">
        </el-input>
      </div>
      <div class="login-form-item">
        <div class="input-label">Password</div>
        <el-input
          v-model="loginInfo.password"
          type="password"
          placeholder="password"
        >
        </el-input>
      </div>
      <div class="login-actions">
        <button class="login-button" @click="userLogin">Login</button>
        &nbsp;
        <button class="register-button" @click="sw">Sign Up Here!</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getRandomNumCanvas } from "@/utils";
import axios from "axios";
import { throttle } from "lodash";
export default {
  data() {
    return {
      loginyzm: "",
      inputVue: "",
      login: false,
      input: "",
      loginInfo: {
        password: "",
        phone: "",
      },

      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      width: 120,
      height: 40,
      imgCode: "",
      imhCode1: "",
    };
  },
  watch: {
    login(newValue, oldValue) {
      console.log(1111);
      this.imgCode = this.draw();
    },
  },
  mounted() {
    // 初始化绘制图片验证码
    this.imgCode = this.draw();
  },
  methods: {
    // 需要节流的方法
    handleClick: throttle(function () {
      // 处理点击事件
    }, 1000), // 1000毫秒的节流

    sw() {
      this.$router.push("/register");
    },

    userLogin: throttle(function () {
      if (this.loginInfo.phone == "" || this.loginInfo.password == "") {
        this.$message.error("phone or password is null");
        return;
      }
      axios
        .post("/puserSys/puser/login", this.loginInfo, {
          withCredentials: true,
        })
        .then((res) => {
          // console.log("userLogin", res.data);
          sessionStorage.setItem("tokenUrl", "");
          if (res.data.data) {
            sessionStorage.setItem("token", res.data.data.userid);
            sessionStorage.setItem("tokenUrl", res.data.data.Token);
            sessionStorage.setItem(
              "loginInfopassword",
              this.loginInfo.password
            );
            sessionStorage.setItem("phone", this.loginInfo.phone);
            this.$message.success("Login success");
            this.$router.push("/");
          } else {
            this.$message.error("Account or Password Error or captcha error");
          }
        })
        .catch(() => {});
    }, 3000),
    logon: throttle(function () {
      if (this.logonInfo.phone == "" || this.logonInfo.password == "") {
        this.$message.error("password or phone or captcha is null");
        return;
      }
      if (this.logonInfo.phone.length < 3 || this.logonInfo.phone.length > 30) {
        this.$message.error("phone length is 1 to 30 digits");
        return;
      }

      if (
        this.logonInfo.password.length < 6 ||
        this.logonInfo.password.length > 10
      ) {
        this.$message.error("Password length is 6 to 10 digits");
        return;
      }
      //  console.log(this.logonInfo);
      axios
        .post("/puserSys/puser/logon", this.logonInfo)
        .then((res) => {
          console.log(res);
          if ((res.data.msg = "Logon Success")) {
            this.$message.success("Registration successful, please log in");
            this.login = !this.login;
            //console.log(res);
            return;
          }

          if ((res.data.msg = "User Already Exists")) {
            this.$message.error("User Already Exists");
            return;
          }
        })
        .catch(() => {});
    }, 3000),

    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    handleDraw() {
      this.imgCode1 = this.draw();
    },
    draw() {
      const canvas = document.getElementById("canvas");
      this.loginyzm = getRandomNumCanvas({
        canvas,
        allTexts: this.allTexts,
        width: this.width,
        height: this.height,
      });
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/public/login.png") no-repeat;
  background-size: 100% 100%;
}

.login-form {
  padding-top: 42vh;
  width: 80%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.login-form-item {
  display: flex;
  padding: 20px 0;
  border-radius: 10px;
  position: relative;
  gap: 10px;
}

.login-form-item .input-label {
  position: absolute;
  top: -4px;
  left: 0;
  background-color: #f5982f;
  padding: 4px 8px 2px;
  border-radius: 4px;
  font-size: 14px;
}

.login-form-item .input-label .label-icon {
  position: absolute;
  right: -30px;
  top: 0px;
  height: 18px;
}

.img-canvas {
  height: 40px;
  border-radius: 8px;
}

#canvas {
  border-radius: 4px;
}

.login-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.login-button,
.register-button {
  padding: 10px 15px;
  font-size: 20px;
  border: none;
  font-weight: bold;
  font-size: 24px;
}

.login-button {
  width: 100%;
  border-radius: 6px;
  background-color: #f5982f;
}

.register-button {
  margin-top: 30px;
  border-radius: 12px;
}

:deep(.el-input) {
  border-radius: 8px;
}

:deep(.el-input .el-input__inner) {
  font-size: 20px;
  font-weight: bold;
  border: none !important;
}

:deep(.el-input__inner::-webkit-input-placeholder) {
  font-size: 15px;
  font-weight: bold;
}

:deep(.el-input__prefix) {
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
}
</style>
